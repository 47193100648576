import { useCallback, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { pushNotification } from "../redux/reducers/notificationsSlice";

export const useAxiosInterceptor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const DEFAULT_ERROR_NOTIFICATION = "Something went wrong!";

  const sendErrorToSentry = useCallback((error) => {
    if (!error.response) {
      Sentry.captureException(new Error(error));
      return;
    }
    const { status, data, headers, config } = error.response;

    Sentry.captureException(new Error(error.message), {
      extra: {
        status,
        data,
        headers,
        request: {
          ...config,
          body: config.data,
        },
        time: new Date().toISOString(),
      },
    });
  }, []);

  useEffect(() => {
    const handleErrorResponse = (error) => {
      sendErrorToSentry(error);
      if (error.config?.bypass) {
        return error;
      }

      if ([401, 403, 404].includes(error?.response?.status)) {
        navigate("/error", {
          state: {
            response: !!error.response,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
            request: !!error.request,
          },
        });
        return;
      }

      const message = error?.response?.status
        ? `${error.response.status} ${error.response.statusText}`
        : error?.message || DEFAULT_ERROR_NOTIFICATION;

      dispatch(
        pushNotification({
          title: "Error",
          message,
          hasViewButton: false,
          severity: "error",
        })
      );
    };

    const requestInterceptor = async (config) => {
      const token = await getAccessTokenSilently();
      config.headers.authorization = `Bearer ${token}`;

      if (user && user.email) {
        config.headers["X-User-Email"] = user.email;
      }

      return config;
    };
    const responseInterceptor = (response) => response;
    const errorInterceptor = (error) => {
      handleErrorResponse(error);
      return Promise.reject(error);
    };

    const interceptorSetup = () => {
      axios.interceptors.request.use(requestInterceptor);
      axios.interceptors.response.use(responseInterceptor, errorInterceptor);
    };

    interceptorSetup();
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.response.eject(errorInterceptor);
    };
  }, [dispatch, getAccessTokenSilently, navigate, sendErrorToSentry, user]);
};
