import axios from "axios";

export const fetchOrCreateContact = async (user, isSuperUserAccess) => {
  let res;
  try {
    res = await axios.get(`/api/contact/get-by-email?email=${user.email}`);
    if (!res.data.contact && !isSuperUserAccess) {
      res = await axios.post("/api/contact/create-from-user", user);
    }
    const { contact } = res.data;

    return contact;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const contactGetByEmail = async (email) => {
  return axios.get(`/api/contact/get-by-email?email=${email}`).then((res) => {
    return res.data.contact;
  });
};

export const contactGetAll = async () => {
  return axios.get("/api/contact/get-all").then((res) => {
    return res.data.contacts;
  });
};

export const contactGetAllByCompany = async ({
  companyID,
  parentCompanies,
}) => {
  return axios
    .get(
      `/api/contact/get-all-by-company?companyID=${companyID}&parentCompanies=${JSON.stringify(
        parentCompanies
      )}`
    )
    .then((res) => {
      return res.data.contacts;
    });
};

export const contactGetAllBusiness = async () => {
  return axios.get("/api/contact/get-all-business").then((res) => {
    return res.data.contacts;
  });
};

export const contactGetAllServiceProviders = async () => {
  return axios.get("/api/contact/get-all-service-providers").then((res) => {
    return res.data.contacts;
  });
};

export const contactGetCustomerVendorNames = async () => {
  return axios.get("/api/contact/get-customer-vendor-names").then((res) => {
    return res.data.contacts;
  });
};

export const contactGetAllManufactures = async () => {
  return axios.get("/api/contact/get-all-manufactures").then((res) => {
    return res.data.manufacturers;
  });
};

export const contactGetAllShippers = async () => {
  return axios.get("/api/contact/get-all-shippers").then((res) => {
    return res.data.shippers;
  });
};

export const contactGetAllSuppliers = async () => {
  return axios.get("/api/contact/get-all-suppliers").then((res) => {
    return res.data.suppliers;
  });
};

export const contactGetAllWarehousesForSupplier = async ({
  parentCompanyID,
  isWarehouse,
}) => {
  return axios
    .get(
      `/api/contact/get-all-warehouses-for-supplier?parentCompanyID=${parentCompanyID}&isWarehouse=${isWarehouse}`
    )
    .then((res) => {
      return res.data.warehouses;
    });
};

export const contactCheckIfEmailExists = async ({ contactID, email }) => {
  return axios
    .get(
      `/api/contact/check-if-email-exists?contactID=${contactID}&email=${email}`
    )
    .then((res) => {
      return res.data.result;
    });
};

export const contactSetUpdatesSeen = async ({ id, updateVersionsSeen }) => {
  return axios
    .patch(`/api/contact/set-updates-seen`, { id, updateVersionsSeen })
    .then((res) => {
      return res.data.contact;
    });
};

export const contactAdd = async (contact) => {
  return axios.post("/api/contact/add", contact).then((res) => {
    return res.data.contact;
  });
};

export const contactGetProfilePicture = async (contactID) => {
  return axios
    .get(`/api/contact/get-profile-img?contactID=${contactID}`)
    .then((res) => {
      return res.data.profilePicture;
    });
};

export const contactAddProfilePicture = async ({ formData }) => {
  return axios
    .patch("/api/contact/add-profile-img", formData, {
      bypass: true,
    })
    .then((res) => {
      return res.data.contact;
    });
};

export const contactUpdate = async (contact) => {
  return axios.put("/api/contact/update", contact).then((res) => {
    return res.data.contact;
  });
};

export const contactSetTermsAndConditions = async ({ id }) => {
  return axios
    .patch(`/api/contact/set-terms-and-conditions`, { id })
    .then((res) => {
      return res.data.contact;
    });
};

export const contactUpdateThemeSettings = async ({
  contactID,
  themeSettings,
}) => {
  return axios
    .patch(`/api/contact/update-theme-settings`, { contactID, themeSettings })
    .then((res) => {
      return res.data.themeSettings;
    });
};

export const contactGetFacilityEmployees = async ({ facilityID }) => {
  return axios
    .get(`/api/contact/get-facility-employees?facilityID=${facilityID}`)
    .then((res) => {
      return res.data.contacts;
    });
};

export const contactGetEmployeesAndServiceProvidersForFacility = async ({
  facilityID,
}) => {
  return axios
    .get(`/api/contact/get-employees-and-service-providers-for-facility`, {
      params: { facilityID },
    })
    .then((res) => {
      return res.data.contacts;
    });
};

export const contactGetAllRegulators = async () => {
  return axios.get("/api/contact/get-all-regulators").then((res) => {
    return res.data.regulators;
  });
};
