import React, { useState, useEffect, Suspense } from "react";

import {
  Modal,
  Grid2,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";

const CustomModal = ({
  anyoneCanEdit,
  children,
  close,
  hasEdit = false,
  isNew,
  open,
  title,
  noAction,
  maxWidth,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(isNew ? true : false);
  }, [isNew]);

  return (
    (<Modal
      sx={{
        mt: 2,
        overflow: "scroll",
      }}
      open={open}
      onClose={(event, reason) => close(event, reason)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid2
        container
        maxWidth={maxWidth ?? "md"}
        sx={{
          margin: "auto",
        }}
      >
        <Grid2 size={12}>
          <Card>
            <CardHeader
              className="centered-container"
              title={title}
              action={
                !noAction && (
                  <>
                    {hasEdit &&
                      !isNew &&
                      (appUserLevel?.includes("Super User") ||
                        anyoneCanEdit) && (
                        <IconButton
                          aria-label="edit"
                          onClick={() => setEditMode(!editMode)}
                        >
                          {editMode ? "Cancel Edit" : "Edit"}
                          <EditIcon />
                        </IconButton>
                      )}
                    <IconButton aria-label="close" onClick={() => close()}>
                      <CloseIcon />
                    </IconButton>
                  </>
                )
              }
            />
            <CardContent>
              <Suspense
                fallback={
                  <Grid2 className="centered-container" size={12}>
                    <CircularProgress />
                  </Grid2>
                }
              >
                {React.cloneElement(children, {
                  ...((hasEdit || isNew) && { editMode: editMode }),
                })}
              </Suspense>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Modal>)
  );
};

export default CustomModal;
