import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Grid2, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf";

GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Grid2
      container
      className="centered-container"
      sx={{ position: "relative" }}
    >
      <IconButton
        sx={{
          position: "absolute",
          bottom: window.innerWidth <= 760 ? "5px" : "50%",
          left: "5px",
          zIndex: 100,
          color: "black",
        }}
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        <ArrowBackIosNewIcon />
      </IconButton>

      <div
        style={{
          overflow: "auto",
        }}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            width={window.innerWidth <= 760 ? 340 : 720}
          />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>

      <IconButton
        sx={{
          position: "absolute",
          bottom: window.innerWidth <= 760 ? "5px" : "50%",
          right: "5px",
          zIndex: 100,
          color: "black",
        }}
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Grid2>
  );
};

export default PDFViewer;
