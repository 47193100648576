import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useContact from "./customHooks/contacts/useContact";
import SplashScreen from "./components/splashScreen/SplashScreen";
import AppError from "./components/ErrorBoundaries/AppError";
import ThemeProviderWrapper from "./components/app/ThemeProviderWrapper";
import AppStateProvider from "./components/app/AppStateProvider";
import NotificationHandler from "./components/app/NotificationHandler";
import App from "./components/app/App";
import { useAxiosInterceptor } from "./customHooks/useAxiosInterceptor";

const RootApp = () => {
  useAxiosInterceptor();
  const { isLoading, isError } = useContact();

  if (isLoading) return <SplashScreen />;
  if (isError) return <AppError fullPage />;

  return (
    <ThemeProviderWrapper>
      <AppStateProvider>
        <Suspense fallback={<SplashScreen />}>
          <ErrorBoundary FallbackComponent={() => <AppError fullPage />}>
            <NotificationHandler />
            <App />
          </ErrorBoundary>
        </Suspense>
      </AppStateProvider>
    </ThemeProviderWrapper>
  );
};

export default RootApp;
