import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrCreateContact } from "../../api/contactAPI";
import {
  selectSuperUserUserAccessEmail,
  setAppUserLevel,
} from "../../redux/reducers/userLevelsSlice";
import { setUser } from "../../redux/reducers/userSlice";
import { useEffect } from "react";
import { getAppUserLevel } from "../../sharedFunctions/userLevels";

const useContact = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const superUserUserAccessEmail = useSelector(selectSuperUserUserAccessEmail);
  const {
    data: contact,
    isLoading: isContactLoading,
    isError: isContactError,
  } = useQuery({
    queryKey: ["contact", superUserUserAccessEmail || user.email],
    queryFn: () =>
      fetchOrCreateContact(
        superUserUserAccessEmail ? { email: superUserUserAccessEmail } : user,
        superUserUserAccessEmail ? true : false
      ),
    enabled: !!superUserUserAccessEmail || !!user,
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (contact) {
      const tempUserLevels = getAppUserLevel(contact);
      dispatch(setAppUserLevel(tempUserLevels));
      const { profilePicture } = contact;

      const contactData = {
        ...contact,
        isVerified: user?.email_verified,
      };

      if (contact.useSocialLogInProfilePicture ?? !profilePicture) {
        contact.profilePicture = user.picture;
      }
      dispatch(setUser(contactData));
    }
  }, [contact, dispatch, user.email_verified, user.picture]);

  const isLoading = isContactLoading;
  const isError = isContactError;

  return { isLoading, isError };
};

export default useContact;
