import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contact: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.contact = action.payload;
    },
    resetUser: (state) => {
      state.contact = null;
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export const selectUser = (state) => state.user.contact;
export default userSlice.reducer;
