import React, { useEffect, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router";
import { Button, Container, Grid2, Typography } from "@mui/material";
import logo from "../../imgs/drOPsLogoSplash.png";
import PublicConfirmShippingForm from "./PublicConfirmShippingForm";
import DropsShippingOrderConfirmation from "../orders/DropsShippingOrderConfirmation";
import { getBySupplierToken } from "../../api/publicFormAPI";
import { Loader } from "../sharedComponents";

const PublicDropsShippingOrderConfirmation = ({ setInvalidLink }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [order, setOrder] = useState(null);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBySupplierToken(token)
      .then((order) => {
        if (
          order?.status === "Awaiting Stock" ||
          order?.status === "Processing" ||
          order?.status === "Pending"
        ) {
          setOrderConfirmed(false);
        } else {
          setOrderConfirmed(true);
        }

        if (!order) setInvalidLink(true);
        setOrder(order);
        setLoading(false);
      })
      .catch(() => setInvalidLink(true));
  }, [setInvalidLink, token]);

  if (loading) return <Loader />;

  return (
    <>
      {orderConfirmed && (
        <Grid2 className="centered-container" size={12}>
          <Typography variant="h6">Order Confirmed, Thank You</Typography>
        </Grid2>
      )}
      <DropsShippingOrderConfirmation
        close={() => {
          setOrderConfirmed(true);
        }}
        editMode={!orderConfirmed}
        order={order}
        publicComponent={true}
      />
    </>
  );
};

const PublicForms = () => {
  const [invalidLink, setInvalidLink] = useState(false);

  return (
    <Container
      className="center-both-container"
      sx={{ marginTop: "20px", marginBottom: "5px" }}
      maxWidth="md"
    >
      <Grid2 container spacing={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.replace("/")}
          sx={{ width: 150, position: "absolute", top: 10, right: 10 }}
        >
          Navigate to app
        </Button>
        <Grid2 size={12}>
          <img
            src={logo}
            alt="drOPs logo"
            width={window.innerWidth < 760 ? 350 : 500}
            onClick={() => window.location.replace("/")}
          />
        </Grid2>
        {invalidLink ? (
          <Grid2 size={12}>
            <Typography variant="h4">Invalid Link</Typography>
            <Typography>
              Please contact{" "}
              <a href="mailto:support@dropsolutions.ca">
                support@dropsolutions.ca
              </a>{" "}
              for assistance.
            </Typography>
          </Grid2>
        ) : (
          <Routes>
            <Route
              path="/confirm-order"
              element={
                <PublicDropsShippingOrderConfirmation
                  setInvalidLink={setInvalidLink}
                />
              }
            />
            <Route
              path="/confirm-shipping"
              element={
                <PublicConfirmShippingForm setInvalidLink={setInvalidLink} />
              }
            />
          </Routes>
        )}
      </Grid2>
    </Container>
  );
};

export default PublicForms;
