import React from "react";
import { FormControlLabel, FormGroup, Grid2, Switch } from "@mui/material";
import InfoText from "../InfoText";

const FormSwitch = ({
  checked,
  onChange,
  label,
  name,
  disabled,
  xs,
  md,
  lg,
  xl,
  sx,
  infoText,
  justifyContent,
}) => {
  return (
    <Grid2
      sx={sx}
      justifyContent={justifyContent}
      className="vertical-centered-container"
      size={{
        xs: xs,
        md: md,
        lg: lg,
        xl: xl,
      }}
    >
      <FormGroup sx={{ ml: 1 }}>
        <FormControlLabel
          control={
            <Switch disabled={disabled} checked={checked} onChange={onChange} />
          }
          label={label}
          name={name}
        />
      </FormGroup>
      {infoText && <InfoText text={infoText} />}
    </Grid2>
  );
};

export default FormSwitch;
