import React, { useEffect } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams } from "react-router";
import keys from "../../config/keys";
import SplashScreen from "../splashScreen/SplashScreen";
import { Box, Typography } from "@mui/material";
import axios from "axios";

// let deferredPrompt;

const Authentication = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  let [searchParams] = useSearchParams();
  // const [isStandalonePWA, setIsStandalonePWA] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/api/quick-books/authorize-callback") {
      axios.get(
        `/api/quick-books/authorize-callback?code=${searchParams.get(
          "code"
        )}&state=${searchParams.get("state")}&realmId=${searchParams.get(
          "realmId"
        )}`
      );
    }
  }, [searchParams]);

  useEffect(() => {
    // Check if the pathname is your Jira callback URI
    if (window.location.pathname === "/api/atlassian/connect-callback") {
      // Make a request to your backend to handle the Jira callback
      axios.get(
        `/api/atlassian/connect-callback?code=${searchParams.get(
          "code"
        )}&state=${searchParams.get("state")}`
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (window.location.pathname === "/auth/google/callback")
      axios
        .get(`/auth/google/callback?code=${searchParams.get("code")}`)
        .then((response) => {
          // Redirect to the authorization URL
          window.location.href = response.data.url;
        })
        .catch((error) => console.error("Error fetching auth URL:", error));
  }, [searchParams]);

  if (window.location.pathname === "/api/quick-books/authorize-callback") {
    return <Typography>Connected to QuickBooks</Typography>;
  }
  if (window.location.pathname === "/api/atlassian/connect-callback") {
    return <Typography>Connected to Jira</Typography>;
  }
  if (window.location.pathname === "/auth/google/callback") {
    return <Typography>Connected to Google</Typography>;
  }

  if (isLoading) return <SplashScreen />;
  if (
    window.matchMedia("(display-mode: standalone)").matches &&
    !isAuthenticated
  ) {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
    });
    return <SplashScreen />;
  }

  if (!isAuthenticated) {
    return (
      <>
        <Box display={"flex"} justifyContent={"end"}>
          {/* {!isStandalonePWA && (
            <Button
              display="flex"
              m={2}
              variant="contained"
              color="primary"
              onClick={() => {
                if (deferredPrompt) {
                  deferredPrompt.prompt();

                  deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === "accepted") {
                    }
                    deferredPrompt = null;
                  });
                } else alert("App already downloaded");
              }}
              sx={{ m: 1 }}
            >
              {"Download App"}
            </Button>
          )} */}
        </Box>
        <Box>
          <SplashScreen hasLogIn={true} />
        </Box>
      </>
    );
  }

  return children;
};

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={keys.AUTH0_DOMAIN}
      clientId={keys.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: keys.AUTH0_AUDIENCE,
        scope: "openid profile email",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Authentication>{children}</Authentication>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
