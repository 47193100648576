import axios from "axios";

export const emailSendNewUserRequest = async ({
  name,
  userEmail,
  requestType,
}) => {
  return axios.post("/api/email/send-new-user-request", {
    name,
    userEmail,
    requestType,
  });
};

export const emailSendProductSupplierRequest = async ({
  facilityName,
  productName,
  userEmail,
}) => {
  return axios.post("/api/email/send-product-supplier-request", {
    facilityName,
    productName,
    userEmail,
  });
};

export const emailSendProductRequest = async ({
  supplierName,
  productDetails,
  userEmail,
}) => {
  return axios.post("/api/email/send-product-request", {
    supplierName,
    productDetails,
    userEmail,
  });
};

export const emailSendEquipmentRequest = async (formData) => {
  return axios.post("/api/email/send-equipment-request", formData);
};

export const emailSendReportProblem = async (body) => {
  return axios.post("/api/email/send-report-problem", body);
};

export const emailSendFeedback = async ({ body }) => {
  return axios.post("/api/email/send-feedback", { body });
};

export const emailSendAppDownloadGuide = async (body) => {
  return axios.post("/api/email/send-app-download-guide", body);
};

export const emailSendOrderToFacility = async (orders) => {
  return axios.post("/api/email/send-order-to-facility", orders);
};

export const emailSendPOToSupplier = async ({ orderID, hasBeenUpdated }) => {
  return axios.post("/api/email/send-PO-to-supplier", {
    orderID,
    hasBeenUpdated,
  });
};

export const emailSendServicePOToSupplier = async ({ serviceRequestID }) => {
  return axios.post("/api/email/send-service-PO-to-supplier", {
    serviceRequestID,
  });
};
// LEGACY
export const emailSendServiceContractPOToSupplier = async ({
  serviceContractID,
}) => {
  return axios.post("/api/email/send-service-contract-PO-to-supplier", {
    serviceContractID,
  });
};

export const emailSendShippingDetails = async (formData) => {
  return axios.post("/api/email/send-shipping-details", formData);
};

export const emailSendConfirmShipping = async ({ orderID }) => {
  return axios.post("/api/email/send-confirm-shipping", { orderID });
};

export const emailSendShippingConfirmation = async ({
  orderID,
  updatedArriveDate,
}) => {
  return axios.post("/api/email/send-shipping-confirmation", {
    orderID,
    updatedArriveDate,
  });
};

export const emailSendDeliveryDateUpdated = async (body) => {
  return axios.post("/api/email/send-delivery-date-update", body);
};

export const emailSendPickUpConfirmation = async ({
  orderID,
  hasBeenUpdated,
}) => {
  return axios.post("/api/email/send-pick-up-confirmation", {
    orderID,
    hasBeenUpdated,
  });
};

export const emailSendInvoiceToFacility = async ({ orderID, orderNumber }) => {
  return axios.post("/api/email/send-invoice-to-facility", {
    orderID,
    orderNumber,
  });
};

export const emailSendServiceContractInvoiceToFacility = async ({
  serviceContractID,
  orderNumber,
}) => {
  return axios.post("/api/email/send-service-contract-invoice-to-facility", {
    serviceContractID,
    orderNumber,
  });
};

export const emailSendReturnableContainer = async (orderID) => {
  return axios.post("/api/email/send-returnable-container", { orderID });
};

export const emailSendOrderReturn = async ({
  orderNumber,
  productsBeingReturned,
  returnReason,
  requestingUser,
}) => {
  return axios.post("/api/email/send-order-return", {
    orderNumber,
    productsBeingReturned,
    returnReason,
    requestingUser,
  });
};

export const emailSendServiceContractRenew = async ({
  facilityName,
  supplierEmails,
  supplierID,
}) => {
  return axios.post("/api/email/send-service-contract-renew", {
    facilityName,
    supplierEmails,
    supplierID,
  });
};

export const emailSendServiceContractCancel = async ({
  facilityEmails,
  facilityName,
  supplierEmails,
  removalReason,
  serviceContractName,
}) => {
  return axios.post("/api/email/send-service-contract-cancel", {
    facilityEmails,
    facilityName,
    supplierEmails,
    removalReason,
    serviceContractName,
  });
};

export const emailSendServiceRequestDateConfirmation = async ({
  serviceRequestID,
}) => {
  return axios.post("/api/email/send-service-request-date-confirmation", {
    serviceRequestID,
  });
};

export const emailSendServiceContractServiceDateConfirmation = async ({
  serviceContractID,
  serviceDate,
  updated,
}) => {
  return axios.post(
    "/api/email/send-service-contract-service-date-confirmation",
    {
      serviceContractID,
      serviceDate,
      updated,
    }
  );
};

export const emailSendServiceContractDateChangeRequested = async ({
  facilityName,
  newServiceDate,
  reason,
  supplierEmails,
  serviceContractName,
  serviceDateToChange,
  supplierID,
}) => {
  return axios.post("/api/email/send-service-contract-date-change-requested", {
    facilityName,
    newServiceDate,
    reason,
    supplierEmails,
    serviceContractName,
    serviceDateToChange,
    supplierID,
  });
};

export const emailSendServiceContractConfirmation = async ({
  serviceContractID,
}) => {
  return axios.post("/api/email/send-service-contract-confirmation", {
    serviceContractID,
  });
};

export const emailSendServiceRequest = async ({
  serviceRequestID,
  updated,
}) => {
  return axios.post("/api/email/send-service-request", {
    serviceRequestID,
    updated,
  });
};

export const emailSendServiceRequestToDrops = async ({ serviceRequestID }) => {
  return axios.post("/api/email/send-service-request-to-drops", {
    serviceRequestID,
  });
};

export const emailSendServiceRequestCancel = async ({
  serviceRequestID,
  removalReason,
}) => {
  return axios.post("/api/email/send-service-request-cancel", {
    serviceRequestID,
    removalReason,
  });
};

export const emailSendActionAssistance = async ({
  actionTitle,
  actionRequestName,
  facilityID,
  notificationContact,
  supplierID,
}) => {
  return axios.post("/api/email/send-action-assistance", {
    actionTitle,

    actionRequestName,
    facilityID,
    notificationContact,
    supplierID,
  });
};

export const emailSendCustomService = async ({ buttonPath, subject, text }) => {
  return axios.post("/api/email/send-custom-service", {
    buttonPath,
    subject,
    text,
  });
};

export const emailSendProductOrderConfirmShippingDetails = async (orderID) => {
  return axios.post("/api/email/send-product-order-confirm-shipping-details", {
    orderID,
  });
};
