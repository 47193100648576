import * as Sentry from "@sentry/browser";

export const loadState = (store) => {
  const serializedState = localStorage.getItem(store);
  if (serializedState === null) {
    return undefined;
  }
  return JSON.parse(serializedState);
};

const getSize = () => {
  let total = 0;
  for (let i = 0; i < localStorage.length; i++) {
    total += localStorage.getItem(localStorage.key(i)).length;
  }
  return total;
};

export const saveState = (state) => {
  try {
    const MAX_STORAGE_SIZE = 5 * 1024 * 1024; // 5 MB
    const { formData, products, userLevels } = state;
    localStorage.clear();

    if (JSON.stringify(products).length + getSize() >= MAX_STORAGE_SIZE) return;
    localStorage.setItem("products", JSON.stringify(products));
    if (JSON.stringify(formData).length + getSize() >= MAX_STORAGE_SIZE) return;
    localStorage.setItem("formData", JSON.stringify(formData));
    if (JSON.stringify(userLevels).length + getSize() >= MAX_STORAGE_SIZE)
      return;
    localStorage.setItem("userLevels", JSON.stringify(userLevels));
  } catch (error) {
    const { formData, products, userLevels } = state;
    Sentry.captureException(error, {
      extra: {
        action: "saveState",
        state: state,
        productsSize: JSON.stringify(products).length + getSize(),
        formDataSize: JSON.stringify(formData).length + getSize(),
        userLevelSize: JSON.stringify(userLevels).length + getSize(),
      },
    });
    throw error;
  }
};

export const clearState = (state) => {
  const { formData, products } = state;
  localStorage.removeItem("formData", JSON.stringify(formData));
  localStorage.removeItem("products", JSON.stringify(products));
  localStorage.removeItem("userLevels");
};
