import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Grid2, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf";

GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";

const PDFPreview = (props) => {
  const { file } = props;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    (<Grid2 sx={{ position: "relative" }} size={12}>
      <IconButton
        sx={{
          position: "absolute",
          bottom: "30%",
          left: 0,
          zIndex: 1,
        }}
        onClick={() => setPageNumber(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <div
        className="centered-container"
        style={{
          overflow: "hidden",
          height: 150,
        }}
      >
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={200} />
        </Document>
      </div>
      <IconButton
        sx={{
          position: "absolute",
          bottom: "30%",
          right: 0,
          zIndex: 1,
        }}
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Grid2>)
  );
};

export default PDFPreview;
