import React, { useState } from "react";
import { Button, Container, Grid2, Typography } from "@mui/material";
import logo from "../../imgs/drOPsLogoSplash.png";
import AppError from "../ErrorBoundaries/AppError";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const EmailConfirmation = () => {
  const { user } = useAuth0();
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(false);

  const resendVerificationEmail = async () => {
    const response = await axios.post("/api/auth/resend-verification-email", {
      userID: user.sub,
    });

    if (response.status === 200) {
      setEmailSent(true);
    } else if (response.error) {
      setError(true);
    }
  };

  if (error)
    return (
      <AppError
        fullPage
        message="There was a problem resending the verification email"
        type="Verification Email"
      />
    );
  return (
    (<Container sx={{ height: "100vh" }} className="vertical-centered-container">
      <Grid2 container justifyContent="center" spacing={2}>
        <Grid2 size={12}>
          <Typography variant="h2" align="center">
            Welcome to
            <div>
              <img src={logo} alt={"drOPs logo"} width={250} height={90} />
            </div>
          </Typography>
        </Grid2>

        <Grid2 size={12}>
          <Typography variant="h6" align="center">
            Please confirm your email. If you cannot find a confirmation email
            please check your junk and spam folders. If you still cannot find an
            email contact our support.
          </Typography>
        </Grid2>
        <Grid2 display={"flex"} justifyContent={"center"} size={12}>
          {emailSent ? (
            "Thank You the email has been sent"
          ) : (
            <>
              <Button variant="contained" onClick={resendVerificationEmail}>
                Resend Verification Email
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  (window.location.href = "mailto:support@dropsolutions.ca")
                }
                sx={{ marginLeft: 2 }}
              >
                Email Support
              </Button>
            </>
          )}
        </Grid2>
      </Grid2>
    </Container>)
  );
};

export default EmailConfirmation;
