import React, { memo } from "react";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";
import { selectSuperUserUserAccessEmail } from "../../redux/reducers/userLevelsSlice";
import { useSelector } from "react-redux";
import EmailConfirmation from "./EmailConfirmation";
import useCartSubmissionsByRequestedUser from "../../customHooks/cartSubmissions/useCartSubmissionsByRequestedUser";
import { selectUser } from "../../redux/reducers/userSlice";
import SplashScreen from "../splashScreen/SplashScreen";

const AppStateProvider = memo(function AppStateProvider({ children }) {
  const contact = useSelector(selectUser);
  const superUserUserAccessEmail = useSelector(selectSuperUserUserAccessEmail);
  useCartSubmissionsByRequestedUser(contact?._id);

  if (!contact) return <SplashScreen />; // Wait until contact data is available
  if (!contact.isVerified) return <EmailConfirmation />;
  if (!contact.acceptedTermsAndConditions && !superUserUserAccessEmail)
    return <TermsAndConditions contact={contact} />;

  return children;
});

export default AppStateProvider;
